import request from "@/lib/http";

export function Login(data){
  return request({
      url: `Auth/Login`,
      method:'post',
      data
  })
}

// 查询预约
export function BookingDetail(params){
  return request({
      url: `Booking/Detail`,
      method:'get',
      params
  })
}
// 新增预约
export function BookingAdd(data){
  return request({
      url: `Booking/Add`,
      method:'post',
      data
  })
}
// 修改预约
export function BookingUpdate(data){
  return request({
      url: `Booking/Update`,
      method:'post',
      data
  })
}
// 取消预约
export function BookingDelete(data){
  return request({
      url: `Booking/Delete`,
      method:'post',
      data
  })
}
