import Vue from 'vue'
import router from './router'

import { Field } from 'vant'
import { Button } from 'vant'
import { Picker } from 'vant'
import { Popup } from 'vant'
import { Icon } from 'vant'
import { Image } from 'vant'
import { DatetimePicker } from 'vant'
import { Form } from 'vant'
import { Tabbar, TabbarItem } from 'vant';
import { DropdownMenu, DropdownItem } from 'vant';
import { Tab, Tabs } from 'vant';
import { NavBar } from 'vant';
import { Grid, GridItem } from 'vant';
import { ActionSheet } from 'vant';
import { Uploader } from "vant";
import { PullRefresh } from "vant";
import { List, Cell, CellGroup } from "vant";
import { Search } from 'vant';
import { Collapse, CollapseItem } from 'vant';
import { Toast } from 'vant';
import { Loading } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Lazyload } from 'vant';
import { Panel } from 'vant';
import { Dialog } from 'vant';

import "lib-flexible/flexible"

import App from './App.vue'



Vue.use(Field)
Vue.use(Button)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(Icon)
Vue.use(Image)
Vue.use(DatetimePicker)
Vue.use(Form)
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(NavBar);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(ActionSheet);
Vue.use(Uploader);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Search);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Toast);
Vue.use(Loading);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Panel);
Vue.use(Dialog);


import './assets/style/common.css'

// Vue.use(ImagePreview);
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
