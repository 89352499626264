/*
 * @Description:
 * @Author: wangjieqiong
 * @Date: 2021-10-19 12:52:21
 * @LastEditors: wangjieqiong
 * @LastEditTime: 2023-07-21 10:08:28
 * @FilePath: \booking-app\src\lib\http.js
 */
import axios from 'axios';

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 5000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (sessionStorage.getItem('token') !== null) {
      config.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
    }

    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);
// 拦截响应
service.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { config, data, status } = error.response;
    if (status == 409) {
      return {
        status: 409,
        data: data,
      };
    }
    return data;
  }
);
export default service;
