<template>
  <div class="page-contanter" id="index">
      <!-- <van-nav-bar
        title="车牌录入"
        fixed
        placeholder
        /> -->
      <div class="plate-wrapper">
          <div class="plate-num">
            <input type="text" :class="{active:currentCheckIndex==0}" readonly v-model="plateNum[0]" @click="provKeyboardShow(0)">
            <input type="text" :class="{active:currentCheckIndex==1}" readonly v-model="plateNum[1]" @click="provKeyboardShow(1,true)">
            <input type="text" :class="{active:currentCheckIndex==2}" readonly v-model="plateNum[2]" @click="provKeyboardShow(2)">
            <input type="text" :class="{active:currentCheckIndex==3}" readonly v-model="plateNum[3]" @click="provKeyboardShow(3)">
            <input type="text" :class="{active:currentCheckIndex==4}" readonly v-model="plateNum[4]" @click="provKeyboardShow(4)">
            <input type="text" :class="{active:currentCheckIndex==5}" v-model="plateNum[5]" @click="provKeyboardShow(5)">
            <input type="text" :class="{active:currentCheckIndex==6}" readonly v-model="plateNum[6]" @click="provKeyboardShow(6)">
            <input v-if="newEnergyCar" readonly type="text" :class="{active:currentCheckIndex==7}" v-model="plateNum[7]" @click="provKeyboardShow(7)">
            <!-- <input type="text" />
            <input type="text" />
            <input type="text" />
            <input type="text" />
            <input type="text" />
            <input type="text" />
            <input type="text" /> -->
        </div>
        <div class="plate-num-switch" @click="switchCarType">
            <svg t="1634304546118" class="icon" style="width: 1em;height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5425"><path d="M724 461H300c-19.9 0-36-16.1-36-36s16.1-36 36-36h333.3l-23.1-21.8c-14.5-13.6-15.1-36.4-1.5-50.9s36.4-15.1 50.9-1.5l89.1 84c10.7 10.1 14.2 25.8 8.8 39.5C752 452 738.8 461 724 461zM389.1 719c-8.9 0-17.7-3.2-24.7-9.8l-89.1-84c-10.7-10.1-14.2-25.8-8.8-39.5C272 572 285.2 563 300 563h424c19.9 0 36 16.1 36 36s-16.1 36-36 36H390.7l23.1 21.8c14.5 13.6 15.1 36.4 1.5 50.9-7 7.5-16.6 11.3-26.2 11.3z" p-id="5426"></path><path d="M512 1024c-69.1 0-136.2-13.5-199.3-40.3C251.7 958 197 921 150 874c-47-47-84-101.7-109.7-162.7C13.5 648.2 0 581.1 0 512s13.5-136.2 40.3-199.3C66 251.7 103 197 150 150c47-47 101.7-84 162.7-109.7C375.8 13.5 442.9 0 512 0s136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.3 130.2 40.3 199.3s-13.5 136.2-40.3 199.3C958 772.3 921 827 874 874c-47 47-101.8 83.9-162.7 109.7-63.1 26.8-130.2 40.3-199.3 40.3z m0-952C269.4 72 72 269.4 72 512s197.4 440 440 440 440-197.4 440-440S754.6 72 512 72z" p-id="5427"></path></svg>
            {{ !newEnergyCar ? '切换到新能源车':'切换到普通车' }}
        </div>
        <van-button block type="warning" native-type="submit" @click="onSubmit">提交</van-button>
      </div>
      <div class="plate-log">
          <!-- <a class="log" href="javascript:void(0)">历史记录</a> -->
          <a href="javascript:void(0)" @click="clearLog">清除记录</a>
      </div>

      <van-popup v-model="keyboardVisible" position="bottom" @close="closeKeyboard">
        <ProvKeyboard v-if="currentCheckIndex === 0" @getValue="getValue" />
        <Keyboard v-if="currentCheckIndex !== 0" :numberDisable="numberDisable" @getValue="getValue" />
      </van-popup>
  </div>
</template>

<script>
import { BookingDetail } from "../api";
import ProvKeyboard from "../components/ProvKeyboard.vue";
import Keyboard from "../components/Keyboard.vue";
export default {
    name: "Index",
    data(){
        return {
            keyboardVisible:false,
            plateNum:['苏','F','','','','',''],
            currentCheckIndex:null,
            numberDisable:false,
            newEnergyCar:false
        }
    },
    created(){
        let carNumLog = localStorage.getItem('carNum');
        if(carNumLog){
            this.plateNum = carNumLog.split('');
            this.plateNum.length == 7 ? this.newEnergyCar = false : this.newEnergyCar = true;
        }
    },
    components:{
        ProvKeyboard,
        Keyboard
    },
    methods:{
        clearLog(){
            localStorage.clear();
            this.plateNum = this.$options.data().plateNum
        },
        switchCarType(){
            this.newEnergyCar = !this.newEnergyCar;
            if(this.newEnergyCar){
                this.plateNum = ['苏','','','','','','',''];
            }else{
                this.plateNum = ['苏','','','','','',''];
            }
        },
        provKeyboardShow(index,hasNum){
            this.keyboardVisible = true;
            this.currentCheckIndex = index;
            this.numberDisable = hasNum?hasNum:false;
        },
        closeKeyboard(){
            console.log('closed');
            this.currentCheckIndex = null;
        },
        // 获取选中的车牌值
        getValue(val){
            if(val){
                this.$set(this.plateNum,this.currentCheckIndex,val);
                // 输入框后移
                this.currentCheckIndex++;
                let numDisabled = this.currentCheckIndex === 1 ? true : false;
                this.provKeyboardShow(this.currentCheckIndex,numDisabled);
                let len = this.plateNum.length;
                if(this.currentCheckIndex == len){
                    this.keyboardVisible = false;
                }
            }else{
                // del
                // 先删除当前选中的值
                this.$set(this.plateNum,this.currentCheckIndex,'');
                // 非车牌首位就-1
                this.currentCheckIndex > 0 && this.currentCheckIndex--;
            }
            
        },
        async onSubmit(){
            // 调用接口判断车牌
            let carNum = this.plateNum.join('');
            if(this.newEnergyCar===true && carNum.length!==8){
                return this.$toast('请输入完整的车牌号码');
            }
            if(this.newEnergyCar===false && carNum.length!==7){
                return this.$toast('请输入完整的车牌号码');
            }
            try {
                let params = {
                    CarNumber: carNum,//苏Y78UIK
                };
                console.log(params)
                let res = await BookingDetail(params);
                console.log(res);
                if (res.status == 200) {
                    // 输入成功
                    this.$router.push({name:'voucher',params: res.data })
                }else{
                    // 无预约信息
                    this.$router.push({name:'booking',params: { plateNum: carNum } })
                }
            } catch (error) {
                console.log(error);
            }
            //this.$router.push({name:'order'})
        }
    }
}
</script>

<style scoped>
.plate-wrapper{
    background-color: #fff;
    padding: 20px;
}
.plate-num{
    display: flex;
    justify-content: center;
    padding:0 6%;
    flex-wrap: nowrap;
    margin-bottom: 10px;
}
.plate-num span{
    border: 1px solid #ddd;
    flex: 1;
    height: 48px;
    width: 100%;
}
.plate-num span + span{
    border-left:0;
}
.plate-num input{
    border: 1px solid #ddd;
    flex: 1;
    width: 0;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 24px;
}
/* .plate-num input + input{
    border-left:0;
} */
.plate-num input.active{
    border-color: #1989fa;
}
.plate-num-switch{
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    color:#1989fa;
}
.router-icon{
    border-radius: 50%;
    width:38px;
    height: 38px;
    border: 1px solid #ddd;
    line-height: 38px;
    text-align: center;
}
.plate-log{
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    font-size: 16px;
    flex-direction: row-reverse;
}
a{
    color:#1989fa;
}
a.log{
    color:#666;
}
</style>