<!--
车牌第一位
-->
<template>
<div class="keyboard-wrapper">
  <ul class="keyboard-wrapper" v-for="(row,index) in list" :key="index">
      <li v-for="item in row.split('')" :key="item">
        <van-button :disabled="numberDisable && index == 0" type="default" @click="keyboardChecked(item)">{{item}}</van-button>
      </li>
      <li v-if="index == list.length-1" class="keyboard-del">
        <van-button type="default" @click="keyboardDel">
        <svg class="icon" style="width: 1em;height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1363"><path d="M874.666667 202.666667H360.533333c-21.333333 0-40.533333 8.533333-55.466666 23.466666l-217.6 234.666667c-25.6 27.733333-25.6 72.533333 0 100.266667l217.6 234.666666c14.933333 14.933333 34.133333 23.466667 55.466666 23.466667H874.666667c40.533333 0 74.666667-34.133333 74.666666-74.666667V277.333333c0-40.533333-34.133333-74.666667-74.666666-74.666666z m10.666666 544c0 6.4-4.266667 10.666667-10.666666 10.666666H360.533333c-2.133333 0-6.4-2.133333-8.533333-4.266666l-217.6-234.666667c-4.266667-4.266667-4.266667-10.666667 0-14.933333l217.6-234.666667c2.133333-2.133333 4.266667-4.266667 8.533333-4.266667H874.666667c6.4 0 10.666667 4.266667 10.666666 10.666667V746.666667zM684.8 403.2c-12.8-12.8-32-12.8-44.8 0l-64 64-61.866667-61.866667c-12.8-12.8-32-12.8-44.8 0-12.8 12.8-12.8 32 0 44.8l61.866667 61.866667-61.866667 61.866667c-12.8 12.8-12.8 32 0 44.8 6.4 6.4 14.933333 8.533333 23.466667 8.533333s17.066667-2.133333 23.466667-8.533333l61.866666-61.866667L640 618.666667c6.4 6.4 14.933333 8.533333 23.466667 8.533333s17.066667-2.133333 23.466666-8.533333c12.8-12.8 12.8-32 0-44.8L620.8 512l61.866667-61.866667c12.8-12.8 12.8-34.133333 2.133333-46.933333z" p-id="1364"></path></svg>
        </van-button>
      </li>
    </ul>
  <!-- <ul class="keyboard-wrapper">
    <li v-for="(item,index) in list" :key="index">
      <van-button type="default" @click="keyboardChecked(item)">{{item}}</van-button>
    </li>
    <li class="keyboard-del">
      <van-button type="default" @click="keyboardDel">
      <svg class="icon" style="width: 1em;height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1363"><path d="M874.666667 202.666667H360.533333c-21.333333 0-40.533333 8.533333-55.466666 23.466666l-217.6 234.666667c-25.6 27.733333-25.6 72.533333 0 100.266667l217.6 234.666666c14.933333 14.933333 34.133333 23.466667 55.466666 23.466667H874.666667c40.533333 0 74.666667-34.133333 74.666666-74.666667V277.333333c0-40.533333-34.133333-74.666667-74.666666-74.666666z m10.666666 544c0 6.4-4.266667 10.666667-10.666666 10.666666H360.533333c-2.133333 0-6.4-2.133333-8.533333-4.266666l-217.6-234.666667c-4.266667-4.266667-4.266667-10.666667 0-14.933333l217.6-234.666667c2.133333-2.133333 4.266667-4.266667 8.533333-4.266667H874.666667c6.4 0 10.666667 4.266667 10.666666 10.666667V746.666667zM684.8 403.2c-12.8-12.8-32-12.8-44.8 0l-64 64-61.866667-61.866667c-12.8-12.8-32-12.8-44.8 0-12.8 12.8-12.8 32 0 44.8l61.866667 61.866667-61.866667 61.866667c-12.8 12.8-12.8 32 0 44.8 6.4 6.4 14.933333 8.533333 23.466667 8.533333s17.066667-2.133333 23.466667-8.533333l61.866666-61.866667L640 618.666667c6.4 6.4 14.933333 8.533333 23.466667 8.533333s17.066667-2.133333 23.466666-8.533333c12.8-12.8 12.8-32 0-44.8L620.8 512l61.866667-61.866667c12.8-12.8 12.8-34.133333 2.133333-46.933333z" p-id="1364"></path></svg>
      </van-button>
    </li>
  </ul> -->
</div>
</template>

<script>
export default {
  name: "Prov",
  data() {
    return {
      list: [
        "京津冀晋蒙辽吉黑沪",
        "苏浙皖闽赣鲁豫鄂湘",
        "粤桂琼渝川贵云藏陕",
        "甘宁新台使",
        // "WJ",
        // "V",
        // "K",
        // "H",
        // "B",
        // "S",
        // "L",
        // "J",
        // "N",
        // "G",
        // "C",
      ],
    };
  },
  methods:{
      keyboardChecked(val){
          this.$emit('getValue',val)
      },
      keyboardDel(){
          this.$emit('getValue','')
      }
  }
};
</script>

<style scoped>
/* .keyboard-wrapper ul li{
  margin: 5px 2px;
} */
.keyboard-wrapper ul li{
    width:calc(11% - 8px);
}
</style>