<template>
  <div class="page-contanter" id="order">
    <!-- <van-nav-bar left-arrow @click-left="onClickLeft" title="车位预约" fixed placeholder /> -->
    <div class="order-wrapper">
      <section>
        <h3>请选择预约日期</h3>
        <!-- <p>只可提前预约最近{{reservedDays}}天</p> -->
        <div class="time-range date-range">
          <div :class="cDActive == index ? 'time-range-item flex-col active' : 'time-range-item flex-col'" v-for="(item, index) in dateRange" :key="index" @click="checkDate(item, index)">
            <label for="">{{ item.label }}</label>
            <span>{{ item.value }}</span>
          </div>
        </div>
      </section>
      <section>
        <h3>请选择预约时段</h3>
        <!-- <p>每个数字代表一个时段，例如9点表示9:00:00~9:59:59</p>
            <div class="office-time-legend">
                <div class="office-time-legend-item">
                <div class="legend-img used"></div>
                <div class="legend-text">可选</div>
                </div>
                <div class="office-time-legend-item">
                <div class="legend-img disabled"></div>
                <div class="legend-text">已预订</div>
                </div>
                <div class="office-time-legend-item">
                <div class="legend-img checked"></div>
                <div class="legend-text">已选</div>
                </div>
            </div> -->
        <div class="time-range time-r">
          <!-- <div class="time-range-item flex-col active">
                6:00
                </div> -->
          <div
            :class="
              cRange.includes(index) ? 'time-range-item flex-col active' : cToday && currentTime > Number(item.replace(':', '')) ? 'time-range-item flex-col disabled' : 'time-range-item flex-col'
            "
            v-for="(item, index) in timeRange"
            :key="index"
            @click="checkTime(index)"
          >
            {{ item }}
          </div>
        </div>
      </section>
      <van-button block type="info" native-type="submit" @click="onSubmit">立即预约</van-button>
    </div>
  </div>
</template>

<script>
import { BookingAdd } from '../api';
import ProvKeyboard from '../components/ProvKeyboard.vue';
import Keyboard from '../components/Keyboard.vue';
import { formatD } from '../lib';
export default {
  name: 'Booking',
  data() {
    return {
      keyboardVisible: false,
      plateNum: '',
      currentCheckIndex: 0,
      numberDisable: false,
      validTime: ['05:30', '24:00'],
      dateRange: [],
      formData: {
        carNumber: '',
        startTime: '',
        endTime: '',
      },
      cDate: '',
      cToday: false, //选择的是否是今天
      currentTime: +formatD(Date.parse(new Date()), 'hh:mm').replace(':', ''), //当前时间
      cDActive: null,
      cRange: [],
      reservedDays: 7,
    };
  },
  computed: {
    timeRange: function() {
      let result = [];
      let startH = this.validTime[0].split(':')[0];
      let startM = this.validTime[0].split(':')[1];
      let endH = this.validTime[1].split(':')[0];
      let endM = this.validTime[1].split(':')[1];
      let hStep = +endH - +startH;
      let mStep;
      if (endM == startM) {
        //mStep = hStep // 30分钟step
        mStep = 0; // 60分钟step
        if (endM == 30) {
          mStep = 1;
        }
      } else {
        //mStep = ((+endM) - (+startM)) > 0 ? (+hStep)+1 : (+hStep)-1; // 30分钟step
        mStep = +endM - +startM > 0 ? 1 : 0; // 60分钟step
      }
      let steps = hStep + mStep;
      for (let i = 0; i < steps - 1; i++) {
        startM = +startM + 60;
        if (startM >= 60) {
          // 小时+1 分钟置0
          startM = '00';
          startH = +startH + 1 > 9 ? +startH + 1 : '0' + (+startH + 1);
          result[i] = [startH, '' + startM].join(':');
        } else {
          // 小时不变，分钟+30
          startH = +startH > 9 ? +startH : '0' + +startH;
          result[i] = [startH, '' + startM].join(':');
        }
      }
      return [this.validTime[0], ...result, this.validTime[1]];
    },
  },
  created() {
    this.formData.carNumber = this.$route.params.plateNum;

    function getWeek(dateString) {
      let dateArray = dateString.split('/');
      let date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
      return '星期' + '日一二三四五六'.charAt(date.getDay());
    }

    let datestr;
    let myDate = new Date();
    for (let i = 0; i < this.reservedDays; i++) {
      let year = Number(myDate.getFullYear());
      let m = Number(myDate.getMonth()) + 1;
      let d = myDate.getDate();
      datestr = m + '/' + d;
      let item = {
        fullValue: year + '/' + m + '/' + d,
        value: datestr,
        label: getWeek(year + '/' + m + '/' + d),
      };
      this.dateRange.push(item);
      myDate.setTime(myDate.getTime() + 1000 * 60 * 60 * 24);
    }
  },
  components: {
    ProvKeyboard,
    Keyboard,
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    checkDate(item, index) {
      console.log(item.value);
      this.cRange = [];
      this.cDate = item.fullValue;
      this.cDActive = index;

      // 如果选择的日期是今天
      var today = formatD(Date.parse(new Date()), 'yyyy-MM-dd');
      let checkDate = formatD(this.cDate, 'yyyy-MM-dd');
      if (today == checkDate) {
        console.log('选择的是今天', formatD(Date.parse(new Date()), 'hh:mm'));
        this.cToday = true; //今天：true
        let thisTime = +formatD(Date.parse(new Date()), 'hh:mm').replace(':', '');
        let checkTime = Number(this.timeRange[index].replace(':', ''));
        if (thisTime > checkTime) {
          console.log('当前时间不能选择。');
        }
      } else {
        this.cToday = false;
      }
    },
    checkTime(index) {
      if (!this.cDate) {
        return this.$toast('请先选择预约日期');
      }
      if (this.cToday && this.currentTime > Number(this.timeRange[index].replace(':', ''))) {
        return; //this.$toast('该时间无法选择');
      }
      // 完成2个时间的选择
      if (this.cRange.length == 2) {
        this.cRange = [];
      }
      // 选择时间
      this.cRange.push(index);
      this.cRange.sort(function(a, b) {
        return a - b;
      });
    },
    async onSubmit() {
      if (!this.cDate) {
        return this.$toast('请选择预约日期');
      }
      if (this.cRange.length == 0) {
        return this.$toast('请选择预约时段');
      }
      if (this.cRange.length < 2) {
        return this.$toast('预约时段需选择2个');
      }
      let endTime = '';
      if (this.timeRange[this.cRange[1]] == '24:00') {
        endTime = '23:59:59';
      } else {
        endTime = this.timeRange[this.cRange[1]] + ':00';
      }
      this.formData.startTime = this.cDate + ' ' + this.timeRange[this.cRange[0]] + ':00';
      this.formData.endTime = this.cDate + ' ' + endTime;
      console.log(this.formData);
      try {
        let res = await BookingAdd(this.formData);
        // console.log(1, res);
        if (res.status == 200) {
          // 预约成功
          localStorage.setItem('carNum', this.formData.carNumber); // 成功存一下车牌号码
          this.$router.push({ name: 'voucher', params: this.formData });
        } else if (res.status == 409) {
          this.$toast(res.data);
        }
      } catch (error) {
        console.log('error', error);
      }
      //this.$router.push({name:'voucher'})
    },
  },
};
</script>

<style scoped>
.order-wrapper {
  padding-bottom: 20px;
}
section {
  background-color: #fff;
  padding: 20px 20px 0px;
  margin-bottom: 10px;
}
.van-button {
  margin: 0px 20px;
  width: calc(100% - 40px);
}
.office-time-legend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 0;
}
.office-time-legend .office-time-legend-item {
  display: flex;
}
.office-time-legend .legend-img.used {
  background: #fff;
}
.office-time-legend .legend-img.disabled {
  background: #d7d7d7;
}
.office-time-legend .legend-img.checked {
  background: #7ed320;
}
.office-time-legend .legend-img {
  width: 19px;
  height: 19px;
  border-radius: 3px;
  border: 1px solid #ddd;
  margin-right: 5px;
}
.office-time-legend .legend-text {
  font-size: 14px;
  color: #010101;
}
</style>
