<template>
  <div class="page-contanter" id="voucher">
    <!-- <van-nav-bar left-arrow @click-left="onClickLeft" title="" fixed placeholder /> -->
    <div class="bg-blue">
        <van-icon name="passed" />
        <h2>预约凭证</h2>
    </div>
    <div class="order-wrapper">
        <h4>您的车牌</h4>
        <div class="order-plate">
            <span v-for="(item,index) in plateNum.split('')" :key="index">{{item}}</span>
        </div>
        <div class="order-info">
            预约日期
            <span>{{cDate}}</span>
            预约时间
            <span>{{startTime}}- {{endTime}}</span>
        </div>
    </div>
    <van-button block plain type="danger" native-type="submit" @click="onCancel">取消预约</van-button>
  </div>
</template>

<script>
import { BookingDelete } from "../api";
import ProvKeyboard from "../components/ProvKeyboard.vue";
import Keyboard from "../components/Keyboard.vue";
import { formatD } from '../lib'
export default {
  name: "Voucher",
  data() {
    return {
      keyboardVisible: false,
      plateNum: "苏Y78UIK",//苏Y78UIK
      currentCheckIndex: 0,
      numberDisable: false,
      cDate:'',
      startTime: "",
      endTime: "",
    };
  },
  components: {
    ProvKeyboard,
    Keyboard,
  },
  created(){
    this.plateNum = this.$route.params.carNumber;
    this.cDate = formatD(this.$route.params.startTime.replace(/\-/g, '/'),'yyyy年MM月dd日')
    this.startTime = formatD(this.$route.params.startTime.replace(/\-/g, '/'),'hh:mm')
    this.endTime = formatD(this.$route.params.endTime.replace(/\-/g, '/'),'hh:mm')
  },
  methods: {
    onClickLeft() {
      //this.$router.go(-1);
      this.$router.push({name:'index'})
    },
    provKeyboardShow(index, hasNum) {
      this.keyboardVisible = true;
      this.currentCheckIndex = index;
      this.numberDisable = hasNum ? hasNum : false;
    },
    // 获取选中的车牌值
    getValue(val) {
      let len = this.plateNum.length;
      this.$set(this.plateNum, this.currentCheckIndex, val);
      // 输入框后移
      this.currentCheckIndex++;
      let numDisabled = this.currentCheckIndex === 1 ? true : false;
      this.provKeyboardShow(this.currentCheckIndex, numDisabled);
      if (this.currentCheckIndex == len) {
        this.keyboardVisible = false;
        // 调用接口判断车牌
        console.log("车牌号码输入完毕：", this.plateNum.join(""));
      }
    },
    async onCancel(){
        this.$dialog.confirm({
            title: '确定要取消预约凭证吗？',
        })
        .then(async () => {
            // on confirm
            try {
                let params = {
                    CarNumber: this.plateNum,
                };
                let res = await BookingDelete(params);
                console.log(res);
                if (res.status == 200) {
                    this.$router.push({name:'index'})
                }else{
                    
                }
            } catch (error) {
                console.log(error);
            }
        })
        .catch(() => {
            // on cancel
        });
    }
  },
};
</script>

<style scoped>
>>>.van-hairline--bottom::after{
    border-bottom-width:0;
}
#voucher{
    text-align: center;
    height: 100%;
    font-size: 18px;
}
.van-icon{
    font-size: 36px;
    color:#fff;
}
.bg-blue{
    height: 200px;
    background-color: #1989fa;
    padding-top: 30px;
    box-sizing: border-box;
}
.van-button{
    margin: 0px 5%;
    width: 90%;
}
h2{
    text-align: center;
    line-height: 44px;
    color:#fff;
}
h4{
    font-weight: bold;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    margin-bottom: 10px;
}
.order-wrapper{
    position: relative;
    top: -60px;
    transform: translateX(-50%);
    left:50%;
    width: 90%;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 0 20px;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
}
.order-plate{
    width: auto;
    display: inline-block;
    border: 4px solid #1989fa;
    padding: 2px 2px;
    margin-bottom: 20px;
}
.order-plate span{
    font-size: 32px;
    font-weight: bold;
    padding: 0px 6px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    background-color: #fff;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #1989fa;
    border-right: 0;
}
.order-plate span + span{
    border-left: 0;
}
.order-plate span:last-child{
    border-right: 1px solid #1989fa;
}

.order-info{
    text-align: center;
    font-size: 18px;
    color:#333;
}
.order-info span{
    display: block;
    font-size: 22px;
    font-weight: bold;
    padding: 8px 0 12px;
    color: #6eb917;
}
</style>