import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router);
import Index from './pages/index.vue'
import Booking from './pages/booking.vue'
import Voucher from './pages/voucher.vue'
export default new Router({
	routes: [
		{
			path: '/',
			redirect: '/index',
		},
		{
			path: '/index',
			name: 'index',
			component: Index,
			meta:{
				title:'车牌录入'
			}
		},
		{
			path: '/booking/:plateNum',
			name: 'booking',
			component: Booking,
			meta:{
				title:'车位预约'
			}
		},
		{
			path: '/voucher/:carNumber/startTime/:startTime/endTime/:endTime',
			name: 'voucher',
			component: Voucher,
			meta:{
				title:'凭证'
			}
		}
		
	]
});
