<!--
车牌其他位：字母+数字
-->
<template>
  <div class="keyboard-wrapper">
    <template v-if="letterFlag">
      <!-- <div class="carnum_two_row" v-for="(row,index) in list" :key="index">
        <template v-for="item in row.split('')">
          <input :key="item" type="button" class="keyboard_two_btn num" :value="item" :disabled="numberDisable && index == 0" @click="keyboardChecked(item)" />
        </template>
        <template v-if="index == list.length-1">
          <span class="btn_comm keyboard_del_btn" @click="keyboardDel">
            <svg class="icon" style="width: 1em;height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1363"><path d="M874.666667 202.666667H360.533333c-21.333333 0-40.533333 8.533333-55.466666 23.466666l-217.6 234.666667c-25.6 27.733333-25.6 72.533333 0 100.266667l217.6 234.666666c14.933333 14.933333 34.133333 23.466667 55.466666 23.466667H874.666667c40.533333 0 74.666667-34.133333 74.666666-74.666667V277.333333c0-40.533333-34.133333-74.666667-74.666666-74.666666z m10.666666 544c0 6.4-4.266667 10.666667-10.666666 10.666666H360.533333c-2.133333 0-6.4-2.133333-8.533333-4.266666l-217.6-234.666667c-4.266667-4.266667-4.266667-10.666667 0-14.933333l217.6-234.666667c2.133333-2.133333 4.266667-4.266667 8.533333-4.266667H874.666667c6.4 0 10.666667 4.266667 10.666666 10.666667V746.666667zM684.8 403.2c-12.8-12.8-32-12.8-44.8 0l-64 64-61.866667-61.866667c-12.8-12.8-32-12.8-44.8 0-12.8 12.8-12.8 32 0 44.8l61.866667 61.866667-61.866667 61.866667c-12.8 12.8-12.8 32 0 44.8 6.4 6.4 14.933333 8.533333 23.466667 8.533333s17.066667-2.133333 23.466667-8.533333l61.866666-61.866667L640 618.666667c6.4 6.4 14.933333 8.533333 23.466667 8.533333s17.066667-2.133333 23.466666-8.533333c12.8-12.8 12.8-32 0-44.8L620.8 512l61.866667-61.866667c12.8-12.8 12.8-34.133333 2.133333-46.933333z" p-id="1364"></path></svg>
          </span>
        </template>
      </div> -->
    <ul class="keyboard-wrapper" v-for="(row,index) in list" :key="index">
      <li v-for="item in row.split('')" :key="item">
        <van-button :disabled="numberDisable && index == 0" type="default" @click="keyboardChecked(item)">{{item}}</van-button>
      </li>
      <li v-if="index == list.length-1" class="keyboard-del">
        <van-button type="default" @click="keyboardDel">
        <svg class="icon" style="width: 1em;height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1363"><path d="M874.666667 202.666667H360.533333c-21.333333 0-40.533333 8.533333-55.466666 23.466666l-217.6 234.666667c-25.6 27.733333-25.6 72.533333 0 100.266667l217.6 234.666666c14.933333 14.933333 34.133333 23.466667 55.466666 23.466667H874.666667c40.533333 0 74.666667-34.133333 74.666666-74.666667V277.333333c0-40.533333-34.133333-74.666667-74.666666-74.666666z m10.666666 544c0 6.4-4.266667 10.666667-10.666666 10.666666H360.533333c-2.133333 0-6.4-2.133333-8.533333-4.266666l-217.6-234.666667c-4.266667-4.266667-4.266667-10.666667 0-14.933333l217.6-234.666667c2.133333-2.133333 4.266667-4.266667 8.533333-4.266667H874.666667c6.4 0 10.666667 4.266667 10.666666 10.666667V746.666667zM684.8 403.2c-12.8-12.8-32-12.8-44.8 0l-64 64-61.866667-61.866667c-12.8-12.8-32-12.8-44.8 0-12.8 12.8-12.8 32 0 44.8l61.866667 61.866667-61.866667 61.866667c-12.8 12.8-12.8 32 0 44.8 6.4 6.4 14.933333 8.533333 23.466667 8.533333s17.066667-2.133333 23.466667-8.533333l61.866666-61.866667L640 618.666667c6.4 6.4 14.933333 8.533333 23.466667 8.533333s17.066667-2.133333 23.466666-8.533333c12.8-12.8 12.8-32 0-44.8L620.8 512l61.866667-61.866667c12.8-12.8 12.8-34.133333 2.133333-46.933333z" p-id="1364"></path></svg>
        </van-button>
      </li>
    </ul>
    </template>
    <template v-else>
    <ul class="keyboard-wrapper" v-for="(row,index) in letterList" :key="index">
      <li v-for="item in row.split('')" :key="item">
        <van-button type="default" @click="keyboardChecked(item)">{{item}}</van-button>
      </li>
    </ul>
    </template>
  </div>
</template>

<script>
export default {
  name: "Prov",
  props:{
    numberDisable:{
      type:Boolean,
      default:false,
    }
  },
  data() {
    return {
      letterFlag:true,
      list: [
        '1234567890',
        'QWERTYUIOP',
        'ASDFGHJKL',
        'ZXCVBNM字'
      ],
      letterList:[
        '领警学挂港澳试超军'
      ]
    };
  },
  methods:{
      keyboardChecked(val){
        if(val === '字'){
          this.letterFlag = false;
        }else{
          this.$emit('getValue',val);
          this.letterFlag = true;
        }
      },
      keyboardDel(){
          this.$emit('getValue','')
      }
  }
};
</script>

<style scoped>
.keyboard-wrapper ul{
    flex-wrap: nowrap;
}
</style>